import React from 'react';
import logo from './logo.svg';
import './App.css';
import Editor from './components/Editor';
function App() {
  return (
    <>
    <Editor
    ></Editor>
    </>
  );
}

export default App;
